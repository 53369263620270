<script>
export default {
	name: 'SkipToContent',
	functional: true,

	props: {
		title: {
			type: String,
			default: 'Spring til...',
		},

		// id as key, label as value
		content: {
			type: Object,
			default: () => ({
				main: 'Hovedindhold',
				footer: 'Footer',
			}),
		},
	},

	render(h, { props, ...args }) {
		const items = [];
		for (const id in props.content) {
			items.push({
				id,
				label: props.content[id],
			});
		}

		return items.length ? (
			<div class="c-skip-to-content">
				<div class="inline-block py-sm border-2 border-current">
					{props.title && (
						<h2 class="text-h3 px-layout-gutter pb-sm border-b-2 border-current">
							{props.title}
						</h2>
					)}
					<ul>
						{items.map(({ id, label }) => {
							return (
								<li key={id}>
									<a
										href={`#${id}`}
										class="text-body px-layout-gutter py-xs"
										tabindex="1"
									>
										{label}
									</a>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		) : null;
	},
};
</script>

<style>
:where(.c-skip-to-content) {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	height: 0;
	overflow: hidden;
}

:where(.c-skip-to-content:focus-within) {
	height: auto;
	overflow: visible;
}

:where(.c-skip-to-content > div) {
	min-width: 12em;
	color: white;
	background: black;
}

:where(.c-skip-to-content a) {
	display: block;
	width: 100%;
}
</style>
