// Last modified: 2022/05/02 09:35:25
/**
 * Usage:
 * v-trap-focus						// The directive alone will stop the focus from leaving the element
 * v-trap-focus.loop				// The loop modifier will have the trap loop around when tabbing through
 * v-trap-focus=false				// Setting the value to false (not just a falsy value) will disable the trap
 * v-trap-focus={					// Pass in a config object as the value for further niceties:
 * 		active: false,								// Also disables the trap
 * 		additionalElementsBefore: '',				// a query string or an array of HTML elements to add into the tabbable content before the trap itself
 * 		additionalElementsAfter: [elRef1, elRef2],	// a query string or an array of HTML elements to add into the tabbable content after the trap itself
 * 		enterToElement: elRef,						// a query string or a HTML element to move focus to whenever the trap is enabled
 * 		leaveToElement: elRef,						// a query string or a HTML element to move focus to whenever the trap is disabled
 * 		allowTabbingOutsideWindow: true,			// a boolean value. If false (default) measures will be made to keep the tab on-site.
 * }
 *
 * Important: Only use one trap at a time! Due to the trap changing tabbing orders, things can easily go awry if multiple are active.
 **/

const trapFocusCustomData = new Map();

const enableTrap = (el) => {
	const customData = trapFocusCustomData.get(el);
	if (customData) {
		window.addEventListener('focusin', customData.keepFocus);

		let enterEl = customData.binding?.value?.enterToElement;
		if (enterEl && typeof enterEl === 'string') {
			enterEl = document.querySelector(enterEl);
		}
		enterEl?.focus?.();

		// Keep tab in page by setting tabindex on body and on div at the very end of the page
		if (!customData.binding?.value?.allowTabbingOutsideWindow) {
			let lastEl = document.querySelector('.v-trap-focus__last-element');
			if (!lastEl) {
				lastEl = document.createElement('a');
				lastEl.setAttribute('class', 'v-trap-focus__last-element');
				document.body.append(lastEl);
			}
			lastEl.setAttribute('tabindex', 0);
			let firstEl = document.querySelector(
				'.v-trap-focus__first-element'
			);
			if (!firstEl) {
				firstEl = document.createElement('a');
				firstEl.setAttribute('class', 'v-trap-focus__first-element');
				document.body.prepend(firstEl);
			}
			firstEl.setAttribute('tabindex', 0);
		}
	}
};

const disableTrap = (el) => {
	const customData = trapFocusCustomData.get(el);
	if (customData) {
		window.removeEventListener('focusin', customData.keepFocus);

		const active =
			customData?.binding?.value?.active ?? customData?.binding?.value;
		if (typeof active !== 'boolean' || active) {
			let leaveEl = customData.binding?.value?.leaveToElement;
			if (leaveEl && typeof leaveEl === 'string') {
				leaveEl = document.querySelector(leaveEl);
			}
			leaveEl?.focus?.();
		}

		// Remove our extra tabable elements
		const lastEl = document.querySelector('.v-trap-focus__last-element');
		if (lastEl) {
			lastEl.removeAttribute('tabindex', -1);
			lastEl.remove();
		}
		const firstEl = document.querySelector('.v-trap-focus__first-element');
		if (firstEl) {
			firstEl.removeAttribute('tabindex', -1);
			firstEl.remove();
		}
	}
};

export default {
	inserted: (el, binding) => {
		// Custom functional data
		trapFocusCustomData.set(el, {
			binding,
			keepFocus: (e) => {
				if (
					el &&
					(el.offsetWidth ||
						el.offsetHeight ||
						el.getClientRects().length) &&
					window.getComputedStyle(el).visibility !== 'hidden'
				) {
					const customData = trapFocusCustomData.get(el);
					const myBinding = customData.binding || binding;

					// Parsed in elements placed before the trap
					let additionalElementsBefore =
						myBinding?.value?.additionalElementsBefore || [];
					if (additionalElementsBefore) {
						if (typeof additionalElementsBefore === 'string') {
							additionalElementsBefore = additionalElementsBefore
								? [
										...document.querySelectorAll(
											additionalElementsBefore
										),
								  ]
								: [];
						}
						if (!Array.isArray(additionalElementsBefore)) {
							console.warn(
								'"additionalElementsBefore" should be a list of HTML elements or a querySelector string.'
							);
							additionalElementsBefore = [];
						}
					}
					additionalElementsBefore.filter((el) => {
						const path = e.composedPath();
						return !path.includes(el);
					}); // Only elements not already within

					// Parsed in elements placed after the trap
					let additionalElementsAfter =
						myBinding?.value?.additionalElementsAfter || [];
					if (additionalElementsAfter) {
						if (typeof additionalElementsAfter === 'string') {
							additionalElementsAfter = additionalElementsAfter
								? [
										...document.querySelectorAll(
											additionalElementsAfter
										),
								  ]
								: [];
						}
						if (!Array.isArray(additionalElementsAfter)) {
							console.warn(
								'"additionalElementsAfter" should be a list of HTML elements or a querySelector string.'
							);
							additionalElementsAfter = [];
						}
					}
					additionalElementsAfter.filter((el) => {
						const path = e.composedPath();
						return !path.includes(el);
					}); // Only elements not already within

					let focusable = [
						...additionalElementsBefore,
						...el.querySelectorAll(
							'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
						),
						...additionalElementsAfter,
					].filter((el) => {
						return !!(
							el &&
							(el.offsetWidth ||
								el.offsetHeight ||
								el.getClientRects().length) &&
							window.getComputedStyle(el).visibility !== 'hidden'
						);
					});
					const firstFocusable = focusable?.[0];
					const lastFocusable = focusable?.[focusable.length - 1];

					if (e.relatedTarget) {
						// If tapping outside of the focusable list
						if (!focusable.includes(e.target)) {
							e.preventDefault();

							const allFocusable = [
								...document.querySelectorAll(
									'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
								),
							];
							focusable = focusable.filter((el) =>
								allFocusable.includes(el)
							);
							let index = focusable.indexOf(e.relatedTarget);

							if (focusable.length) {
								// Move forwards
								if (
									allFocusable.indexOf(e.target) >=
									allFocusable.indexOf(e.relatedTarget)
								) {
									index++;
									if (index >= focusable.length) {
										if (myBinding?.modifiers?.loop) {
											index = 0;
										} else if (
											e.relatedTarget === lastFocusable
										) {
											lastFocusable.focus();
											return;
										} else if (
											e.relatedTarget === firstFocusable
										) {
											firstFocusable.focus();
											return;
										} else {
											index--;
										}
									}
									focusable[index]?.focus?.();
								} else {
									// Move backwards
									index--;
									if (index < 0) {
										if (myBinding?.modifiers?.loop) {
											index = focusable.length - 1;
										} else if (
											e.relatedTarget === lastFocusable
										) {
											lastFocusable.focus();
											return;
										} else if (
											e.relatedTarget === firstFocusable
										) {
											firstFocusable.focus();
											return;
										} else {
											index++;
										}
									}
									focusable[index]?.focus?.();
								}
							}
						}
					} else {
						firstFocusable?.focus?.();
					}
				}
			},
		});

		// Enable trap
		const active = binding?.value?.active ?? binding?.value;
		if (typeof active !== 'boolean' || active) {
			enableTrap(el);
		}
	},

	update: (el, binding) => {
		// Enable/disable trap
		const active = binding?.value?.active ?? binding?.value;
		if (typeof active !== 'boolean' || active) {
			const customData = trapFocusCustomData.get(el);
			if (customData) {
				customData.binding = binding;
			}
			enableTrap(el);
		} else {
			const customData = trapFocusCustomData.get(el);
			if (customData) {
				customData.binding = binding;
			}
			disableTrap(el);
		}
	},

	unbind: (el) => {
		// Disable trap
		disableTrap(el);

		// Remove custom functional data
		trapFocusCustomData.delete(el);
	},
};
