<template>
	<div class="c-global-overlay overflow-y-scroll">
		<PortalTarget
			:id="`c-global-overlay-${_uid}`"
			v-trap-focus.loop="{
				active: hasContent,
			}"
			:name="name"
			:class="[
				'c-global-overlay__target z-50',
				{ 'c-global-overlay__target--active': hasContent },
			]"
			@change="onChange"
		/>
	</div>
</template>

<script>
import trapFocus from '~/citi-baseline/directives/trap-focus.directive.js';

export default {
	name: 'GlobalOverlay',

	directives: {
		trapFocus,
	},

	props: {
		name: {
			type: String,
			default: 'overlay',
		},
	},

	data() {
		return {
			hasContent: false,
		};
	},

	methods: {
		onChange() {
			this.$nextTick(() => {
				/**
				 * Check whether or not the overlay has
				 * any content, taking transitions, ie.
				 * the -leave-active class into consideration,
				 * and using this to block scroll while
				 * the overlay is active.
				 */
				const child = document.querySelector(
					`#c-global-overlay-${this._uid} > *`
				);

				const classes = child?.classList || [];
				const leaving = Array.from(classes).reduce((acc, cur) => {
					return acc || cur.indexOf('-leave-active') !== -1;
				}, false);

				this.hasContent = child && !leaving;

				const __nuxt = document.body.querySelector('#__nuxt');
				if (this.hasContent) {
					const scrollTopPosition =
						document.body.scrollTop ||
						document.documentElement.scrollTop;
					const scrollLeftPosition =
						document.body.scrollLeft ||
						document.documentElement.scrollLeft;

					__nuxt.style.overflow = 'hidden';
					__nuxt.scrollTop = scrollTopPosition;
					__nuxt.scrollLeft = scrollLeftPosition;
				} else {
					const scrollTopPosition = __nuxt.scrollTop;
					const scrollLeftPosition = __nuxt.scrollLeft;

					__nuxt.style.overflow = null;

					document.body.scrollTop = scrollTopPosition;
					document.documentElement.scrollTop = scrollTopPosition;

					document.body.scrollLeft = scrollLeftPosition;
					document.documentElement.scrollLeft = scrollLeftPosition;
				}

				this.$emit('change', this.hasContent);
			});
		},
	},
};
</script>

<style>
.c-global-overlay__target {
	@apply fixed top-0 left-0 w-full h-screen;
	@apply pointer-events-none;
	min-height: calc(100vh + 1px);
}

.c-global-overlay__target--active > * {
	@apply pointer-events-auto;
}
</style>
