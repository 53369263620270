const cloneDeep = require('clone-deep');

/*
	The default config should typically be set as the values of
	the main solution. These can then be overwritten by the values
	coming from backend (handled in the ThemeConfiguration.vue component).
*/
const defaultConfig = {
	// Testing only
	minify: true, // Can be turned to false for a more readable output in the style tag

	// Setup
	baseFontSize: 16, // For rem conversion
	smViewport: 375, // Lowest value clamp
	mdViewport: 1440, // Midpoint (used for both low-clamp and high-clamp)
	lgViewport: 1920, // Highest value clamp

	// Rules
	colors: {
		/* to be added and treated differently than other values */
	},

	layout: {
		margin: {
			sm: 16,
			md: 64,
			lg: 80,
		},
		gutter: {
			sm: 16,
			md: 32,
			lg: 40,
		},

		/*
			As this solution was started before the column system
			got into place, we don't use it, but instead use a
			custom setup.
		*/
		columns: undefined,

		// The max value that the design can be scaled to (single value, not sm-md-lg).
		// The max will impact columns max scaling as well.
		// undefined equals no max.
		max: 1920,
	},

	spacing: {
		'2xs': {
			sm: 4,
			md: 8,
			lg: 12,
		},
		xs: {
			sm: 12,
			md: 16,
			lg: 20,
		},
		sm: {
			sm: 18,
			md: 24,
			lg: 32,
		},
		md: {
			sm: 30,
			md: 40,
			lg: 56,
		},
		md2: {
			sm: 48,
			md: 50,
			lg: 64,
		},
		lg: {
			sm: 48,
			md: 64,
			lg: 84, // old: 96,
		},
		xl: {
			sm: 60, // old: 78,
			md: 80, // old: 96,
			lg: 112, // old: 128,
		},

		/**
		 * todo: well, don't do this.
		 */
		menu: {
			sm: 56,
			md: 72,
			lg: 72,
		},
		logoWidth: {
			sm: 150,
			md: 211,
			lg: 211,
		},
		partnerWidth: {
			sm: 120,
			md: 206,
			lg: 300,
		},
	},

	// fontSize (and lineHeight and letterSpacing) is a special setup, as special rules are generated
	fontSize: {
		'h1-frontpage': {
			fontSize: {
				sm: 48,
				md: 64,
				lg: 72,
			},
			lineHeight: {
				sm: 1.15,
				md: 1.1,
				lg: 1.1,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		h1: {
			fontSize: {
				sm: 32,
				md: 48,
				lg: 64,
			},
			lineHeight: {
				sm: 1.15,
				md: 1.1,
				lg: 1.1,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		h2: {
			fontSize: {
				sm: 18,
				md: 24,
				lg: 32,
			},
			lineHeight: {
				sm: 1.4,
				md: 1.2,
				lg: 1.2,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		h3: {
			fontSize: {
				sm: 16,
				md: 20,
				lg: 26,
			},
			lineHeight: {
				sm: 1.4,
				md: 1.4,
				lg: 1.4,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'h3-accordion': {
			fontSize: {
				sm: 15,
				md: 18,
				lg: 23,
			},
			lineHeight: {
				sm: 1.4,
				md: 1.4,
				lg: 1.4,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		h4: {
			fontSize: {
				sm: 16,
				md: 20,
				lg: 26,
			},
			lineHeight: {
				sm: 1.4,
				md: 1.4,
				lg: 1.4,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'h4-accordion': {
			fontSize: {
				sm: 13,
				md: 15,
				lg: 19,
			},
			lineHeight: {
				sm: 1.4,
				md: 1.4,
				lg: 1.4,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		h5: {
			fontSize: {
				sm: 14,
				md: 16,
				lg: 20,
			},
			lineHeight: {
				sm: 1.4,
				md: 1.4,
				lg: 1.4,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		h6: {
			fontSize: {
				sm: 12,
				md: 14,
				lg: 18,
			},
			lineHeight: {
				sm: 1.5,
				md: 1.4,
				lg: 1.3,
			},
			letterSpacing: {
				sm: 0.04,
				md: 0.04,
				lg: 0.04,
			},
		},
		h7: {
			fontSize: {
				sm: 12,
				md: 14,
				lg: 18,
			},
			lineHeight: {
				sm: 1.5,
				md: 1.2,
				lg: 1.2,
			},
			letterSpacing: {
				sm: 0.04,
				md: 0.01,
				lg: 0.01,
			},
		},
		h8: {
			fontSize: {
				sm: 10,
				md: 12,
				lg: 16,
			},
			lineHeight: {
				sm: 1.5,
				md: 1.5,
				lg: 1.5,
			},
			letterSpacing: {
				sm: 0.04,
				md: 0.04,
				lg: 0.04,
			},
		},
		body: {
			fontSize: {
				sm: 14,
				md: 16,
				lg: 20,
			},
			lineHeight: {
				sm: 1.4,
				md: 1.4,
				lg: 1.4,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		button: {
			fontSize: {
				sm: 14,
				md: 14,
				lg: 14,
			},
			lineHeight: {
				sm: 1.3,
				md: 1.3,
				lg: 1.3,
			},
			letterSpacing: {
				sm: 0.04,
				md: 0.04,
				lg: 0.04,
			},
		},
		burger: {
			fontSize: {
				sm: 25,
				md: 27,
				lg: 29,
			},
			lineHeight: {
				sm: 2.35,
				md: 2.2,
				lg: 2.2,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
	},

	borderRadius: {
		sm: {
			sm: 10,
			md: 10,
			lg: 10,
		},
		md: {
			sm: 12,
			md: 16,
			lg: 16,
		},
		lg: {
			sm: 12,
			md: 24,
			lg: 32,
		},
	},
};

module.exports = {
	defaultConfig,
	makeThemeUtilities,
	restructureFontSizeObject,
};

// Down here we have some practical functions (shouldn't be altered)
// Function to generate the tailwind utility values.
function makeThemeUtilities() {
	const config = cloneDeep(defaultConfig);
	if (config.fontSize) {
		const fontSizeUtils = restructureFontSizeObject(config.fontSize);
		delete config.fontSize;
		Object.assign(config, fontSizeUtils);
	}
	const obj = {};
	Object.keys(config).forEach((key) => {
		if (typeof config[key] === 'object') {
			Object.keys(config[key]).forEach((subKey) => {
				obj[key] = obj[key] || {};
				obj[key][
					subKey
				] = `var(--theme-${key}-${subKey}, var(--theme-${key}-${subKey}--sm))`;

				// FontSize only:
				if (key === 'fontSize') {
					const lineHeight = config?.lineHeight?.[subKey];
					const letterSpacing = config?.letterSpacing?.[subKey];
					if (lineHeight || letterSpacing) {
						const extras = {};
						if (lineHeight) {
							extras.lineHeight = `var(--theme-lineHeight-${subKey}, var(--theme-lineHeight-${subKey}--sm))`;
						}
						if (letterSpacing) {
							extras.letterSpacing = `var(--theme-letterSpacing-${subKey}, var(--theme-letterSpacing-${subKey})--sm)`;
						}
						obj[key][subKey] = [
							`var(--theme-${key}-${subKey}, var(--theme-${key}-${subKey}--sm))`,
							extras,
						];
					}
				}
			});
		}
	});
	return obj;
}

// Function to restructure font size object
function restructureFontSizeObject(object) {
	return Object.keys(typeof object === 'object' ? object : {}).reduce(
		(newObject, key) => {
			['fontSize', 'lineHeight', 'letterSpacing'].forEach((property) => {
				if (object[key][property]) {
					// Make base object if it doesn't exist
					if (!newObject[property]) {
						newObject[property] = {};
					}

					// Add the sub objects
					newObject[property][key] = object[key][property];
				}
			});

			return newObject;
		},
		{}
	);
}
