<template>
	<div class="c-content-link">
		<NuxtLinkExt
			:class="[
				'group inline-flex items-start',
				'space-x-16 pr-8',
				'duration-500 ease-smooth-out',
			]"
			v-bind="$props"
		>
			<div
				:class="[
					'c-content-link__icon',
					'relative w-xs mt-4',
					'duration-500 ease-smooth-out',
					'flex-shrink-0',
				]"
			>
				<SvgLinkCircle />
			</div>

			<div
				class="
					transform
					group-hover:translate-x-8
					duration-500
					ease-smooth-out
				"
			>
				<div
					:class="[
						'text-body',
						{ 'font-semibold': $scopedSlots.description },
					]"
				>
					<slot></slot>
				</div>

				<div v-if="$scopedSlots.description" class="text-h7">
					<slot name="description"></slot>
				</div>
			</div>
		</NuxtLinkExt>
	</div>
</template>

<script>
import NuxtLinkExt from '~/citi-baseline/components/NuxtLinkExt.vue';
import SvgLinkCircle from '~/assets/svgs/link-circle-icon.svg?inline';

export default {
	name: 'ContentLink',
	components: { SvgLinkCircle },
	inheritAttrs: false,

	props: {
		...NuxtLinkExt.props,
	},
};
</script>

<style lang="postcss">
.c-content-link__icon svg {
	@apply w-full text-extra;
}

.c-content-link__icon svg circle {
	@apply duration-500 ease-smooth-out;

	stroke-dasharray: 75;
	stroke-dashoffset: -37.5;
}

.c-content-link > :hover .c-content-link__icon svg circle,
.group:hover .c-content-link__icon svg circle {
	stroke-dashoffset: 0;
}
</style>
