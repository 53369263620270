var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-content-link"},[_c('NuxtLinkExt',_vm._b({class:[
			'group inline-flex items-start',
			'space-x-16 pr-8',
			'duration-500 ease-smooth-out' ]},'NuxtLinkExt',_vm.$props,false),[_c('div',{class:[
				'c-content-link__icon',
				'relative w-xs mt-4',
				'duration-500 ease-smooth-out',
				'flex-shrink-0' ]},[_c('SvgLinkCircle')],1),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\ttransform\n\t\t\t\tgroup-hover:translate-x-8\n\t\t\t\tduration-500\n\t\t\t\tease-smooth-out\n\t\t\t"},[_c('div',{class:[
					'text-body',
					{ 'font-semibold': _vm.$scopedSlots.description } ]},[_vm._t("default")],2),_vm._v(" "),(_vm.$scopedSlots.description)?_c('div',{staticClass:"text-h7"},[_vm._t("description")],2):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }